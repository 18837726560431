import PEModal from '../vendor/plugin-es6-modal/js/pemodal';
import get_ua from './modules/get_ua';
import get_offset_top from './modules/get_offset_top';
import jump from 'jump.js';

var app = app || {};

app = {
  /*
  * menu_mobile - モバイルメニュー
  */
  menu_mobile:() => {



  },

  /*
  * menu_pc - PCメニュー
  */
  menu_pc:() => {
    // hover action
      let flag = true;
      const target = document.querySelector('#header__item--lineup');
      const header__menuWrap = target.querySelector('.header__menuWrap');



      target.addEventListener('mouseleave', (e) => {
        if(flag === false) {
          flag = true;
          target.classList.remove('header__item--activate');
          header__menuWrap.classList.remove('header__menuWrap--activate');

          setTimeout(() => {
            if(flag) {
              header__menuWrap.style.display = 'none';
            }
          }, 300);
        }
      });

  },

    /*
    * header_anime - ヘッダーアニメーション
    */
    header_anime :() => {
      const window_height = window.innerHeight;
      const header = document.querySelector('#header');
      const pagetop = document.querySelector('#header');
      const footer__contact = document.querySelector('#footer__contact');
      const footer__catalog = document.querySelector('#footer__catalog');
      const header_ignition = window_height / 2;
      const num = 800;

      window.addEventListener('scroll', (event) => {
        let num = document.documentElement.scrollTop || document.body.scrollTop;

        // pagetop gimmick
        (header_ignition < num) ?
          header.classList.add('header--activate'):
          header.classList.remove('header--activate');

        (header_ignition < num) ?
          footer__catalog.classList.add('footer__catalog--activate'):
          footer__catalog.classList.remove('footer__catalog--activate');

        (header_ignition < num) ?
            footer__contact.classList.add('footer__contact--activate'):
            footer__contact.classList.remove('footer__contact--activate');
      });
    },

  /*
   * lazy_load_init_set_status
   */
  lazy_load_init_set_status: (_this, current, status, has_background) => {
    status[current] = {
      image_path: null,
      offset:     null,
      flag:       null,
    };

    // Get background image
    let image_path;
    if(has_background) {
      let style = document.defaultView.getComputedStyle(_this, '');
      if(style !=null) {
        image_path = style.backgroundImage;
        if(image_path.match( /^url\(["']?(.*?)["']?\)/i )[1] != null) {
          image_path = image_path.match( /^url\(["']?(.*?)["']?\)/i )[1];
        }
      }
    } else {
      let img = _this.querySelector('img');
      if(img !=null) {
        image_path = img.src;
        status[current].image_path = image_path;
      }
    }
    status[current].image_path = image_path;

    // get cash
    let img = new Image();
    img.src = image_path;
    if(img.height !== 0) {
      status[current].flag = true;
    }

    let add_style = '';
    if(status[current].flag == null) {
      _this.setAttribute('style', 'background-image: none');
      add_style = 'display: block';
    }
    _this.insertAdjacentHTML('beforeend', `
      <div class="lazyLoadImage" style="${add_style}">
        <img src="/assets/images/loading.gif">
      </div>
    `);
  },

  /*
   * lazy_load_replace_source
   */
  lazy_load_replace_source: (_this, current, status, lazyLoadImage) => {
    _this.setAttribute('style', `background-image: url(${status[current].image_path});`);

    // load image
    let load_image = new Image();
    load_image.src = status[current].image_path;
    load_image.onload = () => {
      lazyLoadImage[current].style.opacity = 0;
      status[current].flag = true;

      setTimeout(() => {
        if(lazyLoadImage[current].parentNode !=null){
          lazyLoadImage[current].parentNode.removeChild(lazyLoadImage[current]);
        }
      }, 300);
    };
  },
};




// Scroll gimmick
window.addEventListener('scroll', (event) => {
  let num = document.documentElement.scrollTop || document.body.scrollTop;
});

document.addEventListener('DOMContentLoaded', () => {
  app.menu_pc();
  app.pickup_lazy_load();

});

window.addEventListener('load', () => {
  app.header_anime();
  app.menu_mobile();
  app.smooth_scroll();
});


$(window).on('load',function(){
  /*
  * メSPメニュー　できればネイティブ化したい
  */
  $('#header__menuBtn').on('click', function() {
    $(this).toggleClass('active');
    $('.header__menu').fadeToggle(200);
    $('.footer__pagetop').fadeToggle(200);
    $('.footer__contact').fadeToggle(200);
    $('.footer__catalog').fadeToggle(200);
  });


  $('.header__menu a').on('click', function() {
    $('#header__menuBtn').toggleClass('active');
    $('.header__menu').fadeToggle(200);
    $('.footer__pagetop').fadeToggle(200);
    $('.footer__contact').fadeToggle(200);
    $('.footer__catalog').fadeToggle(200);
  });
});

/*
* faqs アコーディオン
*/

$('.faq__title').on('click', function () {
  /*クリックでコンテンツを開閉*/
  $(this).next().slideToggle(200);
  /*矢印の向きを変更*/
  $(this).toggleClass('open', 200);
});

/*
* ページ遷移 アニメーション
*/


$(document).ready(function() {
	$(".animsition").animsition({
		inClass: 'fade-in-left-sm',
		outClass: 'fade-out-left-sm',
		inDuration: 1500,
		outDuration: 800,
		linkElement: '.animsition-link',
		// e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
		loading: true,
		loadingParentElement: 'body', //animsition wrapper element
		loadingClass: 'animsition-loading',
		loadingInner: '', // e.g '<img src="loading.svg" />'
		timeout: false,
		timeoutCountdown: 5000,
		onLoadEvent: true,
		browser: [ 'animation-duration', '-webkit-animation-duration'],
		// "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
		// The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
		overlay : false,
		overlayClass : 'animsition-overlay-slide',
		overlayParentElement : 'body',
		transition: function(url){ window.location.href = url; }
	});
});

/*
* calendarスライダー
*/

var swiper = new Swiper(".calender_list", {
	effect: "coverflow",
	grabCursor: true,
	centeredSlides: true,
	slidesPerView: "auto",
	coverflowEffect: {
		rotate: 50,
		stretch: 0,
		depth: 100,
		modifier: 1,
		slideShadows: true,
	},
});

document.querySelector('.slide-1').addEventListener('click', function (e) {
	e.preventDefault();
	swiper.slideTo(0, 0);
});

document.querySelector('.slide-3').addEventListener('click', function (e) {
	e.preventDefault();
	swiper.slideTo(3, 0);
});

/*
* スマホでキーボードが表示された時ボタンを消す
*/

$(document).on('focus', 'input', function(){
  $('.btnAria').hide();
});
$(document).on('blur', 'input', function(){
  $('.btnAriaa').show();
});
